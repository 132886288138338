@import '~src/scss/index.scss';
.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 32rem;
  height: 16rem;
  padding: 2rem;
  background-color: $gray-20;
  border-radius: 16rem;
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }

  &.on {
    flex-direction: row-reverse;
    background-color: $gray-100;

    .switch {
      background-color: $gray-5;
    }
  }

  .switch {
    width: 14rem;
    height: 14rem;
    background-color: $gray-80;
    border-radius: 16rem;
  }
}

