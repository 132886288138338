@import '~src/scss/index.scss';
.url {
  display: flex;
  padding: 6rem 8rem;
  color: $gray-60;
  background-color: $bg-2;
  border-radius: 8rem;
  @include standard-border();
  @include basic-transition();

  &:hover {
    color: $gray-100;
    border: 1rem solid $gray-40;
  }
}
