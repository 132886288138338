@import '~src/scss/index.scss';
// ATTN class names should be taken from https://tailwindcss.com/docs
// ATTN don't replace classes with @each generators! Autocomplete will be broken!


// Flex
.flex-wrap {
  flex-wrap: wrap;
}

// by our rules we use specific rules
.flex-1 {
  flex: 1 1 0;
  min-width: 1px;
}

.flex-auto {
  flex: 1 1 auto;
  min-width: 1px;
}

.flex-none {
  flex: none;
}


// Align items
.items-center {
  align-items: center;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-baseline {
  align-items: baseline;
}

.items-stretch {
  align-items: stretch;
}


// Align self
.self-auto {
  align-self: auto;
}

.self-start {
  align-self: flex-start;
}

.self-end {
  align-self: flex-end;
}

.self-center {
  align-self: center;
}

.self-stretch {
  align-self: stretch;
}


// Justify
.justify-around {
  justify-content: space-around;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}


// Flex direction
.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}


// Typography
.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.leading-0 {
  line-height: 0;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.underline {
  text-decoration: underline;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.object-cover {
  object-fit: cover;
}

.hover\:underline {

  &:hover {
    text-decoration: underline;
  }
}


// Text Color

$colors: (
  ('inherit', 'inherit'),
  ('gray-5', $gray-5),
  ('gray-10', $gray-10),
  ('gray-20', $gray-20),
  ('gray-40', $gray-40),
  ('gray-60', $gray-60),
  ('gray-80', $gray-80),
  ('gray-100', $gray-100),
  ('bg-1', $bg-1),
  ('bg-2', $bg-2),
  ('bg-3', $bg-3),
  ('accent-red-60', $accent-red-60),
  ('accent-red-50', $accent-red-50),
  ('accent-red-10', $accent-red-10),
  ('accent-green-60', $accent-green-60),
  ('accent-green-50', $accent-green-50),
  ('accent-green-10', $accent-green-10),
);

@each $values in $colors {
  $name: nth($values, 1);
  $color: nth($values, 2);

  .color-#{$name} {
    color: $color;
  }
}

// Display
.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}


// Position
.inset-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}


// Overflow
.overflow-hidden {
  overflow: hidden;
}


// Sizing
.w-full {
  width: 100%;
}

.w-1\/12 {
  width: calc(100% / 12);
}

.w-2\/12 {
  width: calc(100% / 6);
}

.w-3\/12 {
  width: 25%;
}

.w-6\/12 {
  width: 50%;
}

.w-max {
  width: max-content;
}

.w-fit {
  width: fit-content;
}

.h-full {
  height: 100%;
}

.min-w-1 {
  min-width: 1px;
}

// Cursor
.cursor-pointer {
  cursor: pointer;
}

.cursor-move {
  cursor: move;
}

.cursor-help {
  cursor: help;
}


// Radius
.radius-full {
  border-radius: 9999px;
}

.radius-8 {
  border-radius: 8rem;
}

.radius-12 {
  border-radius: 12rem;
}

.radius-16 {
  border-radius: 16rem;
}

.radius-24 {
  border-radius: 24rem;
}

.radius-32 {
  border-radius: 32rem;
}

// Custom
.hide-empty:empty {
  display: none;
}

.opacity-03 {
  opacity: 0.3;
}

.opacity-06 {
  opacity: 0.6;
}

.opacity-08 {
  opacity: 0.8;
}

.nowrap {
  white-space: nowrap;
}

$indentMap: (
  'p' : ('padding'),
  'px': ('padding-left', 'padding-right'),
  'py': ('padding-top', 'padding-bottom'),
  'pt': ('padding-top'),
  'pb': ('padding-bottom'),
  'pr': ('padding-right'),
  'pl': ('padding-left'),
  'm' : ('margin'),
  'mx': ('margin-left', 'margin-right'),
  'my': ('margin-top', 'margin-bottom'),
  'mt': ('margin-top'),
  'mb': ('margin-bottom'),
  'mr': ('margin-right'),
  'ml': ('margin-left'),
  'gap': ('gap'),
);

$autoKeys: ('mt', 'mr', 'mb', 'ml', 'mx', 'my', 'gap');

$indentSizes: (2, 4, 6, 8, 10, 12, 14, 16, 20, 24, 26, 32, 36, 40, 48, 52, 56, 60, 64, 72, 80, 84, 92, 96, 100, 120, 124);

@each $key, $cssProps in $indentMap {
  @each $size in $indentSizes {

    .#{$key}-#{$size} {
      $unit: if($size == 2, 'px', 'rem');
      @each $cssProp in $cssProps {
        #{$cssProp}: #{$size}#{$unit};
      }
    }
  }
}

@each $key in $autoKeys {
  .#{$key}-auto {
    @each $cssProp in map-get($indentMap, $key) {
      #{$cssProp}: auto;
    }
  }
}

// Transforms
.rotate-180 {
  transform: rotate(180deg);
}
