@import '~src/scss/index.scss';
.button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 170rem;
  height: 56rem;
  padding: 0 32rem;
  color: $gray-5;
  white-space: nowrap;
  text-align: center;
  text-decoration: none !important;
  vertical-align: top;
  background: none;
  border: 0;
  border-radius: 200rem;
  cursor: pointer;
  transition: all 0.5s $ease-out-quadratic;
  user-select: none;
  @include keyboard-focus;

  &:not(.disabled) {
    @media (hover: hover) {

      &:hover,
      &:focus {
        transition: all 0.2s $ease-out-quadratic;
      }
    }
  }

  &.disabled {
    color: $gray-40;
    background: $gray-20;
    border: 0;
    cursor: not-allowed;
    transition: none;
  }
  @include mobile {
    min-width: 122rem;
    height: 48rem;
  }
}

.size-48 {
  min-width: 108rem;
  height: 48rem;
  @include mobile {
    min-width: 108rem;
    height: 40rem;
  }
}

.size-40 {
  min-width: 119rem;
  height: 40rem;
  padding: 0 24rem;
  @include mobile {
    min-width: 76rem;
    height: 32rem;
    padding: 0 16rem;
  }
}

.size-32 {
  min-width: 56rem;
  height: 32rem;
  padding: 0 18rem;
  @include mobile {
    padding: 0 16rem;
  }
}

.size-28 {
  min-width: 40rem;
  height: 28rem;
  padding: 0 14rem;
}

.content {
  position: relative;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: space-between;
}


// styles
.primary {
  color: $gray-100;
  text-transform: uppercase;
  background-color: transparent;
  border: 1rem solid $gray-40;
  @media (hover: hover) {

    &:not(.disabled) {

      &:hover {
        color: $gray-5;
        background-color: $gray-100;
        border: 1rem solid $gray-100;
      }

      &:active {
        color: $gray-80;
        background: $gray-40;
        border: 1rem solid $gray-40;
      }
    }
  }
}

.white {
  color: $bg-1;
  background-color: $gray-100;

  &:not(.disabled) {
    @media (hover: hover) {

      &:hover {
        background-color: $gray-80;
      }

      &:active {
        color: $gray-80;
        background: $gray-40;
      }
    }
  }
}

@include mobile {

  .fullWidthOnMobile {
    width: 100%;
  }
}
