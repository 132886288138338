@import '~src/scss/index.scss';
.avatar {
  border-radius: 999rem;
}

.inner {
  display: flex;
  align-items: center;

  .size-180 & {
    width: 180rem;
    height: 180rem;
  }
}

.border {
  position: absolute;
  z-index: 10;
  stroke: $gray-100;
  stroke-width: 1px;

  .size-14 & {
    width: 14rem;
    height: 14rem;
  }

  .size-18 & {
    width: 18rem;
    height: 18rem;
  }

  .size-24 & {
    width: 24rem;
    height: 24rem;
  }

  .size-28 & {
    width: 28rem;
    height: 28rem;
  }

  .size-32 & {
    width: 32rem;
    height: 32rem;
  }

  .size-40 & {
    width: 40rem;
    height: 40rem;
  }

  .size-116 & {
    width: 116rem;
    height: 116rem;
  }

  .size-164 & {
    width: 164rem;
    height: 164rem;
  }

  .size-180 & {
    width: 180rem;
    height: 180rem;
  }
}

.image {
  display: block;
  overflow: hidden;
  border-radius: 999rem;
  mask-image: url('/images/avatar-mask.png');
  mask-size: cover;
  mask-repeat: no-repeat;
  mask-position: center;

  .size-14 & {
    width: 14rem;
    height: 14rem;
  }

  .size-18 & {
    width: 18rem;
    height: 18rem;
  }

  .size-24 & {
    width: 24rem;
    height: 24rem;
  }

  .size-28 & {
    width: 28rem;
    height: 28rem;
  }

  .size-32 & {
    width: 32rem;
    height: 32rem;
  }

  .size-40 & {
    width: 40rem;
    height: 40rem;
  }

  .size-116 & {
    width: 116rem;
    height: 116rem;
  }

  .size-164 & {
    width: 164rem;
    height: 164rem;
  }

  .size-180 & {
    width: 180rem;
    height: 180rem;
  }
}
