@import '~src/scss/index.scss';
.bg {
  background-position: center;
}

.logo {
  width: 137rem;
}

.link {
  &:hover {
    color: $gray-100;
  }
}

@include mobile {
  .bg {
    background-image: url('/images/footer_bg_360.webp');
  }
  .content {
    padding-top: 120rem;
    padding-bottom: 24rem;
  }

}

@include desktop {
  .bg {
    background-image: url('/images/footer_bg.webp');
  }
  .content {
    padding-top: 200rem;
    padding-bottom: 32rem;
  }
}
