@import '~src/scss/index.scss';
.title {

}

.text {
  margin-top: 4rem;
}

.closeIcon {
  position: absolute;
  top: 14rem;
  right: 13rem;

  &:hover {
    opacity: 0.6;
  }
}
