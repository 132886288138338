@import '~src/scss/index.scss';
.container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $layer-modal - 1;
  width: 100%;
  overflow: hidden;
  background-color: $bg-2;
  height: 0;
  border-radius: 8rem;
  @include basic-transition();
  @include standard-border();

  &.open {
    height: 277rem;
  }
}

.closeContainer {
  padding: 14rem 24rem;
  border-bottom: 1rem solid $gray-20;
}

.closeBtn {
  border-radius: 50rem;
  padding: 4rem;
  width: fit-content;
  @include standard-border();
}

.arrow {
  position: absolute;
  top: 20rem;
  right: 24rem;
}

.itemContainer {
  position: relative;
  padding: 20rem 24rem;
  border-bottom: 1rem dashed $gray-20;

  &:last-child {
    border-bottom: none;
    padding-bottom: 32rem;
  }
}
