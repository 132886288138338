@import '~src/scss/index.scss';
.item {
  white-space: nowrap;
  text-transform: uppercase;
  @include basic-transition();
}

@include mobile {

  .navbar {
    flex-direction: column;
    width: 100%;
    margin-top: 36rem;
    padding-left: 32rem;
  }

  .item {
    width: fit-content;
    margin-top: 42rem;

    &:first-child {
      margin-top: 0;
    }
  }
}

@include desktop {
  .navbar {
    margin-right: 16rem;
  }

  .item {
    color: $gray-80;
    &:hover {
      color: $gray-100;
    }

    &.active {
      color: $gray-100;
    }
  }
}
