@import '~src/scss/index.scss';
.widthContainer {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}


@include mobile {

  .widthContainer {
    padding: 0 12rem;
    overflow: hidden;
  }
}

@include desktop {

  .widthContainer {
    max-width: 1440rem;
    @media (max-width: 1480px) {
      padding: 0 48rem;
    }
  }
}

