@import '~src/scss/index.scss';
.logo {
  position: absolute;
  max-width: 200rem;
  left: 50%;
  transform: translate(-50%, 0);

  img {
    opacity: 0;
    animation: show 0.5s forwards;
  }
}

@include mobile {
  .logo {
    max-width: 140rem;
  }
}

@keyframes show {from {
  opacity: 0;
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
}

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
