@import '~src/scss/index.scss';
.container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32rem;
    height: 32rem;
    border-radius: 50rem;
    cursor: pointer;
    @include basic-transition();
}

.count {
    color: $gray-5;
    background-color: $gray-80;

    &:hover {
        background-color: $gray-100;
    }

    &.active {
        background-color: $gray-100;
    }
}

.bell {
    color: $gray-60;
    background-color: $bg-2;
    @include standard-border();

    &:hover {
        color: $gray-100;
        border-color: $gray-40;
    }

    &.active {
        color: $gray-100;
        border-color: $gray-40;
    }
}
