@import '~src/scss/index.scss';
.container {
  position: relative;
  @include unselectable-text();
  color: $gray-60;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40rem;
  min-width: 40rem;
  color: $gray-80;
  background: $bg-2;
  border-radius: 8rem 8rem 8rem 8rem;
  border: 1rem solid $gray-20;
  cursor: pointer;
  @include basic-transition();

   &:hover {
     color: $gray-100;
     background: $bg-3;
  }

  &:active {
    color: $gray-100;
    border: 1rem solid $gray-20;
  }

  &.active {
    color: $gray-20;
    border: 1px solid $gray-20;
    background: $bg-1;
    cursor: auto;
  }
}

.arrow {
  padding: 0 12rem;
}

@include mobile {

  .container {
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .container::-webkit-scrollbar {
    display: none;
  }

  .mobileHide {
    display: none;
  }

  .button {
    min-height: 34rem;
    min-width: 34rem;
  }
}
