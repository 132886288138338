@import '~src/scss/index.scss';
html,
body {
  height: 100%;
  color: $gray-100;
  background-color: $bg-1;
  font-weight: 400;
  font-size: 16rem;
  font-family: 'PP Fragment', sans-serif;
}

html {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  font-size: 1px; // this required for "rem"


  @media (max-width: 568px) {
    font-size: 0.2778vw; // 1px for mobiles with 360px screen width
  }
}

body {
  position: relative;
  background-color: $bg-1;
  -webkit-overflow-scrolling: touch;

  &.body-scroll-frozen {
    overflow: hidden;
  }
}

a,
input,
textarea,
button,
select {

  &:focus {
    outline: none;
  }
}

input,
textarea,
button,
select {
  background: none;
  border: none;
  border-radius: 0;
}

legend,
fieldset {
  display: block;
  margin: 0;
  padding: 0;
  border: 0;
}

input,
textarea,
select {

  &:-webkit-autofill,
  &:focus {
    background-color: #fff;
  }

  // Hides autocomplete in Safari
  &::-webkit-contacts-auto-fill-button {
    visibility: hidden;
  }

  // Chrome autofilled background color
  &:-webkit-autofill,
  &:-webkit-autofill:focus {
    -webkit-box-shadow: inset 0 0 0 100rem #fff;
  }
}

select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
  -webkit-appearance: none;
  -moz-appearance: radio-container;
  appearance: normal;
}

select::-ms-expand {
  display: none;
}

// hide yandex iframe for tab click
iframe[name='_ym_native'] {
  display: none;
}

// Hide pixels
[width='0'][height='0'],
[width='1'][height='1'],
.text-ad {
  display: none;
}

#__next,
#hydrateWrapper {
  height: 100%;

  .mobile & {
    @media (min-width: 569px) {
      max-width: 360rem; // the real size in Figma
      margin: 0 auto;
    }
  }
}

.mobile {

  body {

    // Iphone input in modal fix
    &.body-scroll-frozen {
      position: fixed;
      width: 100%;
      height: 100%;
    }
  }

  .onlyDesktop {
    display: none;
  }
}

.desktop {

  .onlyMobile {
    display: none;
  }
}
