@import '~src/scss/index.scss';
.container {
    width: 432rem;
    max-height: 600rem;
    overflow-y: scroll;
    @include custom-scrollbar();
}

.title {
    display: flex;
    justify-content: space-between;
    padding: 12rem 16rem;
    color: $gray-100;
}

@include mobile {

    .container {
        width: 100%;
        max-height: 98vh;
    }
}
