@import '~src/scss/index.scss';
.container {
    position: relative;
    width: 100%;
    padding: 12rem 16rem;
    color: $gray-60;
    border-radius: 8rem;
    @include basic-transition();

    &:hover {
        background-color: $bg-3;
    }

    a {
        color: $gray-80;

        &:hover {
            color: $gray-100;
            text-decoration: underline;
        }
    }
}

.indicator {
    position: absolute;
    top: 16rem;
    right: 20rem;
    width: 8rem;
    height: 8rem;
    background-color: $accent-green-50;
    border-radius: 8rem;
}

@include mobile {

    .container {
        width: 100%;
    }
}
