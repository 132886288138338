@import '~src/scss/index.scss';
.rep {
  display: grid;
  margin: 0;
  cursor: default;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;


  span {
    grid-area: 1 / 1 / 2 / 2;
    line-height: 0.9em;
    will-change: transform;
  }

  span:not(:last-child) {
    -webkit-text-stroke: 1px $gray-40;
    text-stroke: 1px $gray-40;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
    color: rgba(0,0,0,0);
  }
}
