@import '~src/scss/index.scss';
.burger {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32rem;
    height: 32rem;
    border-radius: 50rem;
    cursor: pointer;
    @include standard-border();
}
