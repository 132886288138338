@import '~src/scss/index.scss';
.userLink {
  display: flex;
  align-items: center;
  color: $gray-100;
  @include basic-transition();

  &:hover {
    color: $gray-80;
  }
}
