@import '~src/scss/index.scss';
.btn {
    display: flex;
    align-items: center;
    padding: 12rem 8rem;
    color: $gray-60;
    border-radius: 8rem;
    cursor: pointer;
    @include basic-transition();

    &:hover {
        color: $gray-100;
        background-color: $bg-3;
    }
}
