@import '~src/scss/index.scss';
@keyframes openOverlay {

  from {
    opacity: 0;
  }

  to {
    opacity: 0.95;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $layer-modal - 1;
  width: 100%;
  height: 100%;

  &.mounted {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.overlay::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('/images/overlay.webp');
  background-size: cover;
  opacity: 0.95;
  animation: openOverlay 0.3s $ease-in-out-quadratic;
  content: '';
}
