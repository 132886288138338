@import '~src/scss/index.scss';
.logo {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);

  img {
    opacity: 0;
    animation: show 0.5s forwards;
  }
}

.connect {
  opacity: 0;
  animation: show 0.5s forwards;

  &:hover {
    background-color: $gray-80;
  }

  &:active {
    background-color: $gray-60;
  }
}

@keyframes show {

  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    opacity: 0;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
