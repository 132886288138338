@import '~src/scss/index.scss';
.notification {
  position: relative;
  z-index: 2;
  padding: 16rem;
  background: $bg-2;
  border: 1rem solid $gray-20;
  border-radius: 8rem;
  animation: openNotification 0.3s ease-in-out;

  &.closed {
    z-index: 1;
    opacity: 0;
    transition: all 0.3s ease-in-out;
  }
}

@include mobile {

  .notification {
    width: 100%;
  }
}

@include desktop {

  .notification {
    width: 318rem;
  }
}

@keyframes openNotification {

  from {
    transform: translate3d(0, -100%, 0);
    opacity: 0;
  }

  to {
    transform: none;
    opacity: 1;
  }
}
