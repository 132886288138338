@import '~src/scss/index.scss';
.menu {
  position: absolute;
  z-index: $layer-header - 2;
  width: fit-content;
  color: $gray-100;
  background: $bg-2;
  @include standard-border();
  @include animate-grow();
  border-radius: 8rem;
}

.disable {
  pointer-events: none;
}

.control {
  color: $gray-40;
}

.opened {
  color: $gray-100;
}

.content {
  width: fit-content;
  padding: 8rem;
  overflow: auto;
}

.bottomRight {
  top: calc(100% + 8rem);
  right: 0;

  .arrow {
    right: 16rem;
  }
}

.bottomLeft {
  top: calc(100% + 12rem);
  left: 0;

  .arrow {
    left: 16rem;
  }
}

.center {
  top: calc(100% + 12rem);
  left: 50%;
  transform: translate3d(-50%, 0, 0);

  .arrow {
    left: 50%;
    transform: translate3d(-50%, 0, 0);
  }
}
