@import '~src/scss/index.scss';
.container {
  width: 432px;
  background-color: $bg-2;
  border-radius: 8rem;
  @include standard-border();
  @include basic-transition();

  &:hover {
    border: 1rem solid $gray-40;
  }
}

.media {
  width: 100%;
  min-height: 422rem;
  aspect-ratio: 1 / 1;
  object-fit: cover;
}

.mediaContainer {
  margin: 4rem;
  overflow: hidden;
  border-radius: 8rem;
}

@include mobile {

  .container {
    width: 100%;
    max-width: 100%;
  }
}
