@import '~src/scss/index.scss';
.warning {
  position: fixed;
  right: 48rem;
  bottom: 48rem;
  z-index: 10;
  display: none;
  max-width: 546rem;
  padding: 16rem;
  margin: 1rem 0 1rem 1rem;

  &:before {
    content: '';
    position: absolute;
    background: $gray-20;
    background: linear-gradient(90deg, $gray-20 0%, transparent 100%);
    border-radius: 8rem 0 0 8rem;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  &:after {
    content: '';
    position: absolute;
    background: $bg-2;
    border-radius: 8rem 0 0 8rem;
    width: calc(100% - 2rem);
    height: calc(100% - 2rem);
    top: 1rem;
    left: 1rem;
  }

  & > * {
    position: relative;
    z-index: 10;
  }

  a {
    color: $gray-80;
    text-decoration: underline;
  }

  .glitch {
    position: absolute;
    top: 17px;
    right: -50rem;
    z-index: 0;
    width: 72rem;
    height: 37rem;
    transform: rotate(-90deg);
    background: url('/images/listing_card_glitch.svg');
    background-position: center;
  }
}

.visible {
  display: flex;
}

.icon {
  min-width: 40rem;
  min-height: 40rem;
}

.okBtn {
  width: 40rem;
  min-width: unset;
  border-radius: 8px;
}

@include mobile {

  .warning {
    top: unset;
    right: 10rem;
    bottom: 30rem;
    width: calc(100% - 20rem);
    border-bottom: unset;

    &:before {
      border-radius: 8rem 8rem 0 0;
      background: linear-gradient(180deg, $gray-20 0%, transparent 100%);
    }

    &:after {
      border-radius: 8rem 8rem 0 0;
    }

    .glitch {
      top: unset;
      bottom: -22rem;
      left: 0;
      width: 100%;
      transform: none;
    }
  }
}
