@import '~src/scss/index.scss';
.container {
  width: 256rem;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $gray-60;
  cursor: pointer;
  @include basic-transition();

  &:hover {
    color: $gray-100;
  }

  &:active {
    color: $gray-80;
  }
}

.accountInfo {
  margin-bottom: 8rem;
  padding: 8rem;
  background-color: $bg-3;
  border-radius: 8rem;
  @include standard-border();

  .divider {
    position: relative;
    left: -8rem;
    width: calc(100% + 16rem);
    height: 1rem;
    margin: 8rem 0;
    background-color: $gray-20;
  }
}

.divider {
  position: relative;
  left: -8rem;
  width: calc(100% + 16rem);
  height: 1rem;
  margin: 8rem 0;
  background-color: $gray-20;
}

@include mobile {

  .container {
    width: 100%;
  }
}
