$accent-red-60: #b63b35;
$accent-red-50: #c74038;
$accent-red-10: #321d1c;

$accent-green-60: #62c35e;
$accent-green-50: #6bd666;
$accent-green-10: #223524;

$gray-5: #0d0d0d;
$gray-10: #191919;
$gray-20: #202020;
$gray-40: #525252;
$gray-60: #999999;
$gray-80: #cccccc;
$gray-100: #ffffff;

$bg-1: #000000;
$bg-2: #101010;
$bg-3: #161616;

$gradient: linear-gradient(180deg, #202020 49.94%, rgba(32, 32, 32, 0) 86.21%);
