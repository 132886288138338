@import '~src/scss/index.scss';
.root {
  position: relative;
  @include unselectable-text;
}

.arrow {
  position: absolute;
  top: 0;
  right: 16rem;
  bottom: 0;
  z-index: 2;
  width: 16rem;
  height: 16rem;
  margin: 22rem 0;
  color: $gray-100;
  opacity: 0.6;
  pointer-events: none;
  @include basic-transition();

  svg {
    width: 100%;
    height: 100%;
  }

  &.opened {
    transform: rotate(180deg);
  }
}

.select {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 48rem 0 16rem;
  color: $gray-100;
  white-space: nowrap;
  background-color: $bg-2;
  border-radius: 8rem;
  box-shadow: none;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  @include basic-transition();
  @include standard-border();
  @include placeholder {
    color: $gray-40;
  }

  &::-ms-expand {
    display: none;
  }

  &:hover,
  &.focus {
    color: $gray-40;
    background-color: $bg-1;
  }

  &.filled {
    color: $gray-100;
  }

  &:hover {
    border-color: $gray-40;
  }

  &.focus {
    border-color: $gray-40;
  }

  &.errored {
    background-color: $gray-100;
    border-color: $accent-red-50 !important;
  }

  &.disabled {
    color: $gray-40;
    background-color: $gray-100;
    border-color: $gray-40;
    cursor: not-allowed;
    @include placeholder {
      color: $gray-40;
    }
  }

  .size-60 & {
    height: 60rem;
    font-size: 16rem;
  }
}

.placeholder {
  color: $gray-40;
}

.dropdown {
  position: absolute;
  z-index: 10;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  background-color: $bg-2;
  @include standard-border();
  border-radius: 8rem;

  .size-60 & {
    top: 60rem;
    font-size: 16rem;
  }

  .option {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 16rem;
    text-align: flex-start;


    .size-60 & {
      height: 60rem;
      font-weight: 400;
      font-size: 16rem;
    }

    &:hover {
      background-color: $bg-3;
      cursor: pointer;
    }
  }
}
