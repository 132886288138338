@import '~src/scss/index.scss';
@include mobile {

  .modal {
    background: $gray-20 !important;
    border-radius: 8rem 8rem 0;
  }

  .content {
    padding: 8rem;
  }
}
