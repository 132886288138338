@import '~src/scss/index.scss';
.textarea {
  width: 100%;
  padding: 2rem 0;
  color: $gray-100;
  font-family: 'JetBrains Mono', sans-serif;
  letter-spacing: 0;
  border-radius: 12rem;
  box-shadow: none;
  @include basic-transition();

  &:hover,
  &:focus {
    background-color: transparent;
  }

  &:hover {
    background-color: transparent;
  }

  &:focus {
    background-color: transparent;
  }
}

.borderContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 12rem 16rem;
  border: 1rem solid $gray-20;
  border-radius: 8rem;

  &.withLabel {
    padding: 29rem 16rem 11rem;
  }

  &:hover,
  &:focus {
  }

  &.disabled {
    color: $gray-100;
    border: 1rem solid $gray-10;
    @include placeholder {
      color: $gray-10;
    }
  }

  &.errored {
    border-color: $accent-red-10 !important;
  }

  &.success {
    border-color: $accent-green-10 !important;
  }
}

.label {
  position: absolute;
  transition: all 0.3s;
  pointer-events: none;
  top: 20rem;
  left: 16rem;

  &_small {
    position: absolute;
    top: 11rem;
    left: 16rem;
    font-size: 12px;
    line-height: 16px;
  }
}
