@import '~src/scss/index.scss';
.container {
    padding: 24rem;
}

.walletBtn {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 78rem;
    color: $gray-60;
    background-color: $bg-3;
    border-radius: 8rem;
    cursor: pointer;
    @include standard-border();

    &:hover {
        color: $gray-100;
        border: 1rem solid $gray-40;
    }
}

.divider {
    width: 100%;
    height: 1rem;
    background-color: $gray-20;
}
