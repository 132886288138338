@import '~src/scss/index.scss';
.mini {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32rem;
    height: 32rem;
    color: $gray-5;
    background-color: $gray-100;
    border-radius: 50rem;
    cursor: pointer;

    &:hover {
        background-color: $gray-80;
    }

    &:active {
        background-color: $gray-60;
    }
}
