@import '~src/scss/index.scss';
.item {
  list-style: none;
  background-color: $bg-2;
  border-radius: 8rem;

  &.open {
    background-color: $bg-3;
  }

  a {
    color: $gray-100;

    &:hover {
      text-decoration: underline;
    }
  }
}

.question {
  cursor: pointer;
}

.content {
  height: 0;
  overflow: hidden;
  @include basic-transition();

  &.open {
    height: auto;
  }
}
