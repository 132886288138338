@import '~src/scss/index.scss';
@keyframes shine-lines {

  100% {
    transform: translateX(100%);
  }
}

.bone {
  position: relative;
  display: inline-block;
  overflow: hidden;
  background: $gray-10;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #191919 0%, #202020 53.12%, #191919 100%);
    transform: translateX(-100%);
    animation: shine-lines 3s infinite;
    content: '';
  }
}
