@import '~src/scss/index.scss';
.h1 {
  font-weight: 500;
  font-size: 148rem;
  font-family: 'JetBrains Mono', sans-serif;
  line-height: 156rem;
  letter-spacing: -0.02em;
  @include mobile {
    font-size: 56rem;
    line-height: 64rem;
  }
}

.h2 {
  font-weight: 500;
  font-size: 96rem;
  font-family: 'JetBrains Mono', sans-serif;
  line-height: 108rem;
  letter-spacing: -0.02em;
  @include mobile {
    font-size: 48rem;
    line-height: 56rem;
  }
}

.h3 {
  font-weight: 500;
  font-size: 60rem;
  font-family: 'JetBrains Mono', sans-serif;
  line-height: 64rem;
  letter-spacing: -0.02em;
  @include mobile {
    font-size: 40rem;
    line-height: 48rem;
  }
}

.h4 {
  font-weight: 500;
  font-size: 48rem;
  font-family: 'JetBrains Mono', sans-serif;
  line-height: 56rem;
  @include mobile {
    font-size: 32rem;
    line-height: 40rem;
  }
}

.h5 {
  font-weight: 700;
  font-size: 28rem;
  font-family: 'JetBrains Mono', sans-serif;
  line-height: 36rem;
  @include mobile {
    font-size: 24rem;
    line-height: 32rem;
  }
}

.h6 {
  font-weight: 500;
  font-size: 20rem;
  font-family: 'JetBrains Mono', sans-serif;
  line-height: 28rem;
  @include mobile {
    font-size: 18rem;
    line-height: 24rem;
  }
}

.sub {
  font-weight: 500;
  font-size: 18rem;
  font-family: 'JetBrains Mono', sans-serif;
  line-height: 24rem;
  letter-spacing: 0.02em;
  @include mobile {
    font-size: 16rem;
    line-height: 24rem;
  }
}

.glitch24 {
  font-weight: 400;
  font-size: 24rem;
  font-family: 'VT323', sans-serif;
  line-height: 26rem;
  text-transform: uppercase;
  @include mobile {
    font-size: 24rem;
    line-height: 26rem;
  }
}

.glitch18 {
  font-weight: 400;
  font-size: 18rem;
  font-family: 'VT323', sans-serif;
  line-height: 20rem;
  text-transform: uppercase;
  @include mobile {
    font-size: 18rem;
    line-height: 20rem;
  }
}

.u16 {
  font-weight: 500;
  font-size: 16rem;
  font-family: 'JetBrains Mono', sans-serif;
  line-height: 20rem;
  text-transform: uppercase;
  @include mobile {
    letter-spacing: 0.04em;
  }
}

.u14 {
  font-weight: 500;
  font-size: 14rem;
  font-family: 'JetBrains Mono', sans-serif;
  line-height: 18rem;
  text-transform: uppercase;
  @include mobile {
    letter-spacing: 0.02em;
  }
}

.u12 {
  font-weight: 500;
  font-size: 12rem;
  font-family: 'JetBrains Mono', sans-serif;
  line-height: 16rem;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}

.c16 {
  font-weight: 500;
  font-size: 16rem;
  font-family: 'JetBrains Mono', sans-serif;
  line-height: 20rem;
  @include mobile {
    letter-spacing: 0.04em;
  }
}

.c14 {
  font-weight: 500;
  font-size: 14rem;
  font-family: 'JetBrains Mono', sans-serif;
  line-height: 18rem;
  @include mobile {
    letter-spacing: 0.02em;
  }
}

.c12 {
  font-weight: 500;
  font-size: 12rem;
  font-family: 'JetBrains Mono', sans-serif;
  line-height: 16rem;
  @include mobile {
    letter-spacing: 0.02em;
  }
}

.p1 {
  font-weight: 500;
  font-size: 16rem;
  font-family: 'JetBrains Mono', sans-serif;
  line-height: 24rem;
  letter-spacing: 0.02em;
  @include mobile {
    font-size: 14rem;
    line-height: 22rem;
  }
}

.button-l {
  font-weight: 400;
  font-size: 24rem;
  font-family: 'VT323', sans-serif;
  font-style: normal;
  line-height: 26rem;
  text-transform: uppercase;
  @include mobile {
    font-size: 18rem;
    line-height: 20rem;
  }
}

.button-s {
  font-weight: 400;
  font-size: 18rem;
  font-family: 'VT323', sans-serif;
  font-style: normal;
  line-height: 20rem;
  text-transform: uppercase;
}

.gradient {
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

