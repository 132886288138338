@import '~src/scss/index.scss';
@supports (will-change: transform) {
  @keyframes openModal {

    0% {
      transform: translateY(50%);
      opacity: 0;
    }

    50% {
      opacity: 1;
    }

    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
}

.container {
  animation: openModal 0.35s ease;
  will-change: transform; // this fixes high CPU usage for blurred animation
}

.plainModal {
  position: relative;
  background-color: $bg-2;

  &,
  &:focus {
    outline: none;
  }
}

.closeButton {
  position: absolute !important;
  top: 20rem;
  right: 20rem;
  z-index: 10 !important;
}


@include mobile {

  .container {
    position: absolute;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
  }

  .plainModal {
    width: 100%;
    // max-height: 100%;
  }
}

@include desktop {

  .overlay {
    display: grid;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100%;
    margin: auto;
  }

  .plainModal {
    width: 432rem;
    background-color: $bg-2;
    border-radius: 8rem 8rem 0 0;
    border-bottom: unset;

    &:before {
      content: '';
      position: absolute;
      background: $gray-20;
      background: linear-gradient(180deg, $gray-20 0%, transparent 100%);
      border-radius: 8rem 8rem 0 0;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    &:after {
      content: '';
      position: absolute;
      background: $bg-2;
      border-radius: 8rem 8rem 0 0;
      width: calc(100% - 2rem);
      height: calc(100% - 2rem);
      top: 1rem;
      left: 1rem;
    }

    & > * {
      position: relative;
      z-index: 1;
    }

    .glitch {
      position: absolute;
      bottom: -22rem;
      left: 0;
      z-index: 0;
      width: 100%;
      height: 37rem;
      background: url('/images/listing_card_glitch.svg');
    }
  }

}
