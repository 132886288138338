@import '~src/scss/index.scss';
$sizes: (12, 14, 16, 18, 20, 24, 28, 32, 40, 48, 116, 164, 180);

.icon {
    vertical-align: text-bottom;
}


@each $size in $sizes {

    .size-#{$size} {
        width: #{$size}rem;
        height: #{$size}rem;
    }
}

