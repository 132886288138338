@import '~src/scss/index.scss';
.tooltip {
  position: absolute;
  z-index: $layer-modal + 1;
  display: none;
  max-width: 300rem;
  padding: 12rem 16rem;
  background: $bg-3;
  @include standard-border();
  border-radius: 8rem;

  &.visible {
    display: block;
  }

  a {
    text-decoration: underline;
  }
}
