@import '~src/scss/index.scss';
.container {
  color: $gray-100;
  cursor: pointer;
  @include basic-transition();

  &:hover {
    color: $gray-80;
  }
}

.open {
  color: $gray-60;
}

