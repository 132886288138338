@import '~src/scss/index.scss';
.root {
  position: relative;
  font-family: 'JetBrains Mono', sans-serif;
  letter-spacing: 0;
}

.symbol {
  position: absolute;
  top: 22rem;
  right: 18rem;
  @include unselectable-text;

  &_withLabel {
    right: 45rem;
  }
}

.label {
  position: absolute;
  transition: all 0.3s;
  pointer-events: none;
  top: 20rem;
  left: 16rem;

  &_small {
    position: absolute;
    top: 11rem;
    left: 16rem;
    font-size: 12px;
    line-height: 16px;
  }
}

.tooltipIcon {
  position: absolute;
}

.borderContainer {
  @include basic-transition();
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 60rem;
  padding: 12rem 16rem;
  border: 1rem solid $gray-20;
  border-radius: 8rem;

  &.withLabel {
    padding: 29rem 16rem 11rem;
  }

  &:hover,
  &:focus {
  }

  &.disabled {
    color: $gray-100;
    border: 1rem solid $gray-10;
    @include placeholder {
      color: $gray-10;
    }
  }

  &.errored {
    border-color: $accent-red-10 !important;
  }

  &.success {
    border-color: $accent-green-10 !important;
  }
}

.input {
  width: 100%;
  color: $gray-100;
  font-weight: 500;
  font-size: 16rem;
  line-height: 18rem;
  border: 0 solid transparent !important;
  box-shadow: none;
  @include basic-transition();

  &.withMaxButton {
    padding-right: 76rem;
  }
  @include placeholder();

  &:focus {
    background-color: transparent;
  }

  &::placeholder {
    color: $gray-40;
  }
}


.subContainer {
  display: flex;
  justify-content: space-between;
  height: 12rem;
  margin-top: 6rem;
}

.loading {
  position: absolute;
  top: 30rem;
  left: 16rem;
}
