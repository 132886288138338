@import '~src/scss/index.scss';
.header {
  position: sticky;
  top: 0;
  z-index: $layer-header;

  @include basic-transition
}

.withBg {
  background: url('/images/header_bg.webp');
  background-repeat: repeat-x;
  background-size: contain;
}

.inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 112rem;
}

@include desktop {
  .header {
    min-height: 112rem;
  }
}


@include mobile {

  .header {
    min-height: 68rem;
  }
  .inner {
    min-height: 68rem;
  }
}
